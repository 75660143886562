/*
Lists

Markup:
<ul class="{{modifier_class}}">
<li>List</li>
<li>List</li>
<li>List</li>
</ul>

.checkList - Checklist

Styleguide Base.Lists
*/
 
ul,
ol {
  margin: 0;
  padding: 0;
  padding-left: 20px;

  li {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

ul {
  &.checkList {
    list-style: none;
    padding-left: 25px;

    li {
      position: relative;

      &:before {
        content: $i-check;
        font-family: $icon-family;
        position: absolute;
        left: -25px;
        font-size: 20px;
        margin-top: -4px;
      }
    }
  }
}