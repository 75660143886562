$cookie-primary: $primary;
$cookie-primary-dark: $primary-dark;
$cookie-primary-darken: $primary-darken;
$cookie-primary-contrast: $primary-contrast;
$cookie-primary-dark-contrast: $primary-dark-contrast;
$cookie-primary-darken-contrast: $primary-darken-contrast;
$cookie-black: $black;
$cookie-white: $white;
$cookie-gray-base: $gray-base;
$cookie-light-gray: $light-gray;
$cookie-modal-background: fade_out(#cccccc, 0.3);
$zindex-cookie: 6000;
$cookie-font-size-base: $font-size-base;
$cookie-padding: 15px;
$cookie-padding-top-desktop: 150px;
$cookie-infobox-padding: 30px;

// Basics
$cookie-font-color: $cookie-black;
$cookie-background: $cookie-white;
$cookie-border-color: $cookie-light-gray;

// Buttons
// Base
$cookie-button-radius: $buttonBorderRadius;
$cookie-button-background: $cookie-white;
$cookie-button-border-color: $cookie-gray-base;
$cookie-button-color: $cookie-black;
$cookie-button-font-size: $font-size-base;
$cookie-button-font-weight: $normal;

$cookie-button-background-hover: $cookie-primary;
$cookie-button-border-color-hover: $cookie-primary;
$cookie-button-color-hover: $cookie-primary-contrast;

$cookie-button-background-active: $cookie-primary-dark;
$cookie-button-border-color-active: $cookie-primary-dark;
$cookie-button-color-active: $cookie-primary-dark-contrast;

// Action
$cookie-button-background-action: $cookie-primary;
$cookie-button-border-color-action: $cookie-primary;
$cookie-button-color-action: $cookie-primary-contrast;

$cookie-button-background-action-hover: $cookie-primary-dark;
$cookie-button-border-color-action-hover: $cookie-primary-dark;
$cookie-button-color-action-hover: $cookie-primary-dark-contrast;

$cookie-button-background-action-active: $cookie-primary-darken;
$cookie-button-border-color-action-active: $cookie-primary-darken;
$cookie-button-color-action-active: $cookie-primary-darken-contrast;

// Links
$cookie-link-color: $text-base;
$cookie-link-color-hover: $cookie-primary;
$cookie-link-font-size: $font-size-small;

// Switches
$cookie-switch-background: $cookie-light-gray;
$cookie-switch-background-active: $cookie-primary;
$cookie-switch-background-inactive: $cookie-black;

$cookie-dialog-border-radius: $inputBorderRadius;

$cookie-tab-background-hover: $cookie-background;

$cookie-history-font-size: 1.1rem;

.dcCookieBar {
  position: fixed;
  left: 0;
  bottom: 0;
  line-height: 1.3;
  width: 100%;
  min-width: 320px;
  box-shadow: 0 0 10px rgba(#000000, 20);
  z-index: $zindex-cookie;
  padding: $cookie-padding;
  padding-left: 90px;
  background-color: $cookie-background;
  font-size: $cookie-font-size-base;
  color: $cookie-font-color;
  box-sizing: border-box;

  a {
    color: $cookie-link-color;
    text-decoration: underline;

    &:hover {
      color: $cookie-link-color-hover;
    }
  }

  &__button {
    line-height: 1.6;
    padding: 5px 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    min-width: 40px;
    width: auto;
    font-size: $cookie-button-font-size;
    font-weight: $cookie-button-font-weight;
    color: $cookie-button-color;
    border: 1px solid $cookie-button-border-color;
    background-color: $cookie-button-background;
    border-radius: $cookie-button-radius;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: $cookie-button-background-hover;
      border-color: $cookie-button-border-color-hover;
      color: $cookie-button-color-hover;
    }

    &:active, &:focus {
      background-color: $cookie-button-background-active;
      border-color: $cookie-button-border-color-active;
      color: $cookie-button-color-active;
    }

    @media (max-width: 1024px) {
      font-size: $cookie-font-size-base;
    }
  }

  &__buttonaction {
    border-color: $cookie-button-border-color-action;
    color: $cookie-button-color-action;
    background-color: $cookie-button-background-action;

    &:hover {
      background-color: $cookie-button-background-action-hover;
      border-color: $cookie-button-border-color-action-hover;
      color: $cookie-button-color-action-hover;
    }

    &:active, &:focus {
      background-color: $cookie-button-background-action-active;
      border-color: $cookie-button-border-color-action-active;
      color: $cookie-button-color-action-active;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    .dcCookieBar__button {
      flex-grow: 1;
      margin-left: calc($cookie-padding / 2);
      margin-right: calc($cookie-padding / 2);
      margin-bottom: calc($cookie-padding / 2);
      box-sizing: border-box;
      width: 100%;
      flex-basis: 100% !important;
    }
  }

  &__main {
    text-align: center;
  }

  &__text {
    padding-left: calc($cookie-padding / 2);
    padding-right: calc($cookie-padding / 2);
    padding-bottom: $cookie-padding;
    text-align: left;
  }

  &__links {
    text-align: right;
    font-size: $cookie-link-font-size;

    a {
      display: inline-block;
      vertical-align: middle;
      margin-left: calc($cookie-padding / 2);
      margin-right: calc($cookie-padding / 2);
    }
  }

  &__center {
    text-align: center;
    margin-top: $cookie-padding;

    a {
      display: inline-block;
      vertical-align: middle;
      margin-left: calc($cookie-padding / 2);
      margin-right: calc($cookie-padding / 2);
    }
  }
}

.dcCookieModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: $cookie-modal-background;
  color: $cookie-font-color;
  display: none;
  padding-left: $cookie-padding;
  padding-right: $cookie-padding;
  z-index: $zindex-cookie + 1;
  font-size: $cookie-font-size-base;

  &.dcCookieTopPadding {
    @media (min-width: 441px) {
      padding-top: $cookie-padding-top-desktop;
    }
  }

  &__dialog {
    background-color: #ffffff;
    padding: $cookie-padding*2;
    width: 600px;
    max-width: 100%;
    margin: $cookie-padding auto;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    border-radius: $cookie-dialog-border-radius;

    @media (max-width: 767px) {
      padding: $cookie-padding;
    }
  }

  a {
    color: $cookie-link-color;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $cookie-link-color-hover;
    }
  }

  .dcCookieBar {
    &__button {
      color: $cookie-button-color;
      background-color: $cookie-button-background;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: $cookie-button-background-hover;
        border-color: $cookie-button-border-color-hover;
        color: $cookie-button-color-hover;
      }

      &:active, &:focus {
        background-color: $cookie-button-background-active;
        border-color: $cookie-button-border-color-active;
        color: $cookie-button-color-active;
      }
    }

    &__buttonaction {
      border-color: $cookie-button-background-action;
      color: $cookie-button-color-action;
      background-color: $cookie-button-background-action;
      cursor: pointer;

      &:hover {
        background-color: $cookie-button-background-action-hover;
        border-color: $cookie-button-border-color-action-hover;
        color: $cookie-button-color-action-hover;
      }

      &:active, &:focus {
        background-color: $cookie-button-background-action-active;
        border-color: $cookie-button-border-color-action-active;
        color: $cookie-button-color-action-active;
      }
    }

    &__links {
      margin-top: $cookie-padding;
      text-align: left;

      a {
        margin-left: 0;
        margin-right: $cookie-padding;
      }
    }
  }

  &.open {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.dcCookieModalBody {
  &__headline {
    font-size: 20px;
    margin-bottom: $cookie-padding;
  }

  &__info {
    margin-bottom: $cookie-padding;
  }
}

.dcCookieBarSwitch {
  width: 50px;
  position: relative;
  height: 26px;
  display: inline-block;
  cursor: pointer;
  border-radius: 13px;
  margin: 0;
  background-color: $cookie-switch-background;

  span {
    font-style: normal;
    position: absolute;
    left: 3px;
    width: 20px;
    height: 20px;
    background-color: $cookie-switch-background-inactive;
    top: 50%;
    margin-top: -10px;
    display: block;
    pointer-events: none;
    margin-bottom: 0;
    border-radius: 50%;
    transition: 0.1s ease-in-out;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    &:checked {
      + span {
        left: 100%;
        margin-left: -23px;
        background-color: $cookie-switch-background-active;
      }
    }
  }

  &__label {
    color: $cookie-font-color;
    margin-bottom: 0;
    line-height: 26px;
    margin-right: 10px;
  }
}

.dcCookieBarBox {
  padding: $cookie-padding;
  border: 2px solid $cookie-border-color;
  margin-top: $cookie-padding;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $cookie-padding;
  }

  &__title {
    margin-right: $cookie-padding*2;
    font-size: 16px;
  }

  &__info {
    margin-bottom: $cookie-padding;
  }
}

.dcCookieBarMoreBox {
  margin-top: $cookie-padding;
  //display: none;

  &.open {
    display: block;
  }
}

.dcCookieBarTable {
  margin-top: $cookie-padding;

  &__row {
    padding: 8px 0;
    border-bottom: 1px solid $cookie-border-color;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__cell {
    > a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      width: 90%;
    }
    &--description {
      font-weight: bold;
      font-size: 1.2em;
    }

    &--switch {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    &__row {
      display: flex;
      align-items: center;
    }

    &__cell {
      flex-grow: 1;
      text-align: left;

      &:first-child {
        padding-right: 10px;
        flex-shrink: 0;
        flex-grow: 0;
        width: 200px;

        &.dcCookieBarTable__cell--description {
          width: 50%;
        }
      }

      &--switch {
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 767px) {
    &__cell:first-child {
      margin-bottom: 4px;

      &:after {
        content: ":";
      }
    }
  }
}

.dcCookieBarRecurringTable {
  &__row {
    padding: 8px 0;
    border-bottom: 1px solid $cookie-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0;
    }

    &--parent {
      .dcCookieBarRecurringTable__label {
        position: relative;
        padding-left: 20px;

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 0 6px 10px;
          border-color: transparent transparent transparent $cookie-switch-background-inactive;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -6px;
          transition: 0.2s ease-in-out;
        }

        &.active:before {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          border-color: transparent transparent transparent $cookie-switch-background-active;
        }
      }
    }

    &--child {
      padding-left: 20px;
    }
  }

  &__label {
    font-weight: bold;
  }
}

.dcCookieBarHistoryTable {
  font-size: $cookie-history-font-size;

  &__row {
    padding: 8px 0;
    border-bottom: 1px solid $cookie-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__label {
    flex-grow: 1;
    margin-right: 8px;
  }

  &__badge {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 1px;

    &.active {
      background-color: green;
    }
  }

  &__active {
    margin-left: 8px;
    flex-shrink: 0;
    width: 60px;
    text-align: right;
  }
}

.dcCookieBarRecurring {
  z-index: $zindex-cookie+1;
  position: fixed;
  left: 20px;
  bottom: 20px;
  box-sizing: border-box;
}

.dcCookieBarRecurringBox {
  position: fixed;
  z-index: $zindex-cookie+1;
  left: 20px;
  bottom: 80px;
  background-color: $cookie-background;
  box-shadow: 0 0 20px rgba(#000000, 20);
  width: 400px;
  font-size: $cookie-font-size-base;

  &__close {
    height: 14px;
    width: 14px;
    cursor: pointer;

    svg path {
      fill: #000000;
    }

    &:hover {
      svg path {
        fill: $cookie-primary;
      }
    }
  }

  &__head {
    padding: $cookie-padding;
    border-bottom: 1px solid $cookie-border-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-right: $cookie-padding;
  }

  &__footer {
    .dcCookieBar__button {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 440px) {
    max-width: 100%;
    left: 0;
    bottom: 0;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  }
}

.dcCookieBarRecurringButton {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background-color: $cookie-button-background-action;
  color: $cookie-button-color-action;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(#000000, 20);

  &:hover, &:focus {
    background-color: $cookie-button-background-action-hover;
  }

  svg {
    path {
      fill: #000000;
    }
  }

  .closelabel {
    display: none;
    position: relative;
    top: 3px;
  }

  .openlabel {
    position: relative;
    top: 6px;
  }

  &.open {
    .openlabel {
      display: none;
    }

    .closelabel {
      display: block;
    }
  }
}

.dcCookieBarTab {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;

  &:not(.active) {
    background-color: $cookie-border-color;
  }

  &:hover {
    background-color: $cookie-tab-background-hover;
  }
}

.dcCookieBarTabContent {
  padding: $cookie-padding;
  display: none;
  height: 240px;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;

  &.active {
    display: block;
  }
}

.dcCookieBarTabs {
  &__header {
    display: flex;

    .dcCookieBarTab {
      flex-grow: 1;
      width: 50%;
    }
  }
}

.dcCookieExternal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__info {
    padding: $cookie-infobox-padding;
    border: 1px solid $cookie-button-border-color;
    background-color: #ffffff;
  }

  &__headline {
    padding-bottom: 20px;
  }

  &__button {
    line-height: 1.6;
    padding: 5px 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    min-width: 40px;
    width: auto;
    font-size: $cookie-button-font-size;
    font-weight: $cookie-button-font-weight;
    color: $cookie-button-color;
    border: 1px solid $cookie-button-border-color;
    background-color: $cookie-button-background;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: $cookie-button-background-hover;
      border-color: $cookie-button-border-color-hover;
      color: $cookie-button-color-hover;
    }

    &:active, &:focus {
      background-color: $cookie-button-background-active;
      border-color: $cookie-button-border-color-active;
      color: $cookie-button-color-active;
    }
  }
}

.hidden {
  display: none !important;
}
