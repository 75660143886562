/*
Input Groups

Markup:
<div class="inputGroup {{modifier_class}}">
  <input type="text" />
</div>

.is-focus - Focus

Styleguide Form.Input Groups
*/
/*
Input Groups Addons

Markup:
<div class="inputGroup inputGroup--infieldAddon {{modifier_class}}">
  <input type="text" />
  <span class="inputGroup__addon"><i class="icon icon-search"></i></span>
</div>
<br/>
<div class="inputGroup inputGroup--infieldAddon {{modifier_class}}">
  <span class="inputGroup__addon"><i class="icon icon-search"></i></span>
  <input type="text" />
</div>
<br/>
<div class="inputGroup inputGroup--infieldAddon {{modifier_class}}">
  <input type="text" />
  <button type="submit" class="inputGroup__addon"><i class="icon icon-search"></i></button>
</div>

.is-focus - Focus

Styleguide Form.Input Groups Addons
*/

.inputGroup {
  display: flex;
  width: 100%;
  transition: 0.2s ease-in-out;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  position: relative;

  input,
  .input {
    flex-grow: 1;
    transition: 0.2s ease-in-out;
    &:not(:last-child){
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__addon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: $inputBorderWidth solid $inputBorderColor;
    min-width: $inputGroupAddonWidthXS;
    overflow: hidden;

    @media (min-width: $screen-sm) {
      min-width: $inputGroupAddonWidth;
      height: $inputGroupAddonWidth;
    }

    &:first-child {
      border-radius: 0;
      border-top-left-radius: $inputBorderRadius;
      border-bottom-left-radius: $inputBorderRadius;
      margin-right: -1px;
    }

    &:last-child {
      border-radius: 0;
      border-top-right-radius: $inputBorderRadius;
      border-bottom-right-radius: $inputBorderRadius;
      margin-left: -1px;
    }

    .button {
      min-width: 0;
      border-radius: 0;
      margin: -1px;
    }

    .icon {
      line-height: 1;
      display: block;
      font-size: $inputGroupAddonIconFontSizeXS;

      @media (min-width: $screen-sm) {
        font-size: $inputGroupAddonIconFontSize;
      }
    }

    + input,
    + select {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--clickable {
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }

  button.inputGroup__addon {
    min-width: $inputGroupAddonWidthXS;
    background: transparent;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-sm) {
      min-width: $inputGroupAddonWidth;
    }

    &:hover,&:focus,&:active {
      background: $primary;
      color: var(--main-color-primary-contrast);
    }
  }

  &--infieldAddon {

    .inputGroup__addon {
      position: absolute;
      height: 100%;
      border: 0;
      background: transparent !important;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    > input {

      &:first-child {
        padding-right: $inputGroupAddonWidthXS;
        border-radius: $inputBorderRadius;

        @media (min-width: $screen-sm) {
          padding-right: $inputGroupAddonWidth;
        }
      }

      &:last-child {
        padding-left: $inputGroupAddonWidthXS;
        border-radius: $inputBorderRadius;

        @media (min-width: $screen-sm) {
          padding-left: $inputGroupAddonWidth;
        }
      }
    }
  }

  &.is-focus {
    border-color: $inputBorderColorFocus;

    > input,
    > .input,
    select {
      background: $white;
      border-color: $inputBorderColorFocus;
    }

    .inputGroup__addon {
      background: $white;
      border-color: $inputBorderColorFocus;
    }
  }
}