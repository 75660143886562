body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

#container {
  @media (min-width: $screen-md) {
    max-width: 50%;
  }
}

.mainContentArea {
  flex-grow: 1;
  padding: 40px calc($grid-gap / 2);

  @media (min-width: $screen-sm) {
    padding: 60px $grid-gap;
  }

  .container {
    max-width: 400px;
    padding: 0;
  }
}

.mainAside {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: none;

  img {
    min-width: 100%;
    height: 100% !important;
    position: absolute;
    object-fit: cover;
    object-position: center top;
  }

  @media (min-width: $screen-md) {
    display: block;
  }
}

.mainContent {
  display: flex;
  width: 100%;
}