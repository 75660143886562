
.buttonRow {
  margin-top: $spacer-base*2;
  margin-bottom: $spacer-base*2;
  margin-left: -5px;
  margin-right: -5px;

  .button,
  .buttonLink {
    margin: $spacer-base 5px;
  }

  @media (max-width: $screen-xs) {
    text-align: center;

    .pull-left,
    .pull-right {
      float: none !important;
    }

    .buttonLink,
    .button {
      min-width: 220px;
    }
  }
}

.buttonRowBottom {
  @extend .buttonRow;
  margin-bottom: 0;

  .buttonLink,
  .button {
    margin-bottom: 0;
  }
}

.buttonRowTop {
  @extend .buttonRow;
  margin-top: 0;

  .buttonLink,
  .button {
    margin-top: 0;
  }
}