// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
      @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
  }
}

@mixin text-shadow($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: $string;
}

@mixin box-shadow($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow: $string;
  box-shadow: $string;
}

@mixin drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin inner-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin border-radiuses($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;

  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
  -moz-border-radius-topleft: $topleft;

  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  border-top-left-radius: $topleft;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin opacity($opacity: 0.5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

@mixin gradient($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background: -webkit-linear-gradient(top, $startColor, $endColor);
  background: -moz-linear-gradient(top, $startColor, $endColor);
  background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
}

@mixin horizontal-gradient($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease) {
  -webkit-animation: $name $duration $delay $ease;
  -moz-animation: $name $duration $delay $ease;
  -ms-animation: $name $duration $delay $ease;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}

@mixin transform($string) {
  -webkit-transform: $string;
  -moz-transform: $string;
  -ms-transform: $string;
  -o-transform: $string;
}

@mixin scale($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: scale($factor);
  -ms-transform: scale($factor);
  -o-transform: scale($factor);
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
}

@mixin skew($deg, $deg2) {
  -webkit-transform: skew($deg, $deg2);
  -moz-transform: skew($deg, $deg2);
  -ms-transform: skew($deg, $deg2);
  -o-transform: skew($deg, $deg2);
}

@mixin translate($x, $y:0) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
}

@mixin translate3d($x, $y: 0, $z: 0) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
}

@mixin perspective($value: 1000) {
  -webkit-perspective: $value;
  -moz-perspective: $value;
  -ms-perspective: $value;
  perspective: $value;
}

@mixin transform-origin($x:center, $y:center) {
  -webkit-transform-origin: $x $y;
  -moz-transform-origin: $x $y;
  -ms-transform-origin: $x $y;
  -o-transform-origin: $x $y;
}

@mixin appearance($string) {
  -o-appearance: $string;
  -khtml-appearance: $string;
  -webkit-appearance: $string;
  -moz-appearance: $string;
  -webkit-appearance: $string;
  appearance: $string;
}

@mixin filter($string) {
  -webkit-filter: $string;
  -moz-filter: $string;
  -ms-filter: $string;
  -o-filter: $string;
  filter: $string;
}

@mixin flexbox($direction,$align,$wrap) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;;
  flex-wrap: $wrap;
}


@mixin flexbox($direction:row,$align:center,$wrap:wrap) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-wrap($wrap:wrap) {
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin align-items($align:center) {
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin align-self($align:center) {
  align-self: $align;
  -webkit-align-self: $align;
}

@mixin calc($prop, $val) {
  #{$prop}: calc(#{$val});
  #{$prop}: -moz-calc(#{$val});
  #{$prop}: -webkit-calc(#{$val});
  #{$prop}: -o-calc(#{$val});
}

@mixin line-clamp($count) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@function colorTextContrast($n,$i: $main-color-black) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + calc((blue($n) * 114) / 1000));
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + calc((blue(#ffffff) * 114) / 1000));

  @if abs($color-brightness) < calc($light-color/2) {
    @return #ffffff;
  }@else{
    @return $i;
  }
}

@mixin gridList($breaks: (),$gapColumns: $grid-gap,$gapRows: $grid-gap) {
  display: grid;
  grid-column-gap: $gapColumns;
  grid-row-gap: $gapRows;
  @each $breakpoint, $width in $breaks {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      grid-template-columns: repeat(auto-fill, minmax($width,1fr));
    }
  }
}