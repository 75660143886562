/*
Radioboxes

Markup:
<label class="specialradiobox" for="specialradioboxradio1">
    <input type='radio' name='specialradiobox' id='specialradioboxradio1' value='' checked='checked'>Radio 1<i for="specialradioboxradio1"></i>
</label>
<label class="specialradiobox" for="specialradioboxradio2">
    <input type='radio' name='specialradiobox' id='specialradioboxradio2' value=''>Radio 2<i for="specialradioboxradio2"></i>
</label>

Styleguide Form.Radioboxes
*//*
Radioboxes Switch

Markup:
<label class="specialcheckboxSwitch" for="specialcheckboxSwitchradio1">
    <input type='radio' name='specialcheckboxSwitch' id='specialcheckboxSwitchradio1' value='' checked='checked'>Radio 1<i for="specialcheckboxSwitchradio1"></i>
</label>
<label class="specialcheckboxSwitch" for="specialcheckboxSwitchradio2">
    <input type='radio' name='specialcheckboxSwitch' id='specialcheckboxSwitchradio2' value=''>Radio 2<i for="specialcheckboxSwitchradio2"></i>
</label>

Styleguide Form.Radioboxes Switch
*/
/*
Checkboxes

Markup:
<label class="specialcheckbox" for="checkbox1">
    <input type='checkbox' name='checkbox' id='checkbox1' value=''>Text<i for="checkbox1"></i>
</label>

Styleguide Form.Checkboxes
*/
/*
Checkboxes Switch

Markup:
<label class="specialcheckboxSwitch" for="specialcheckboxSwitch1">
    <input type='checkbox' name='checkbox' id='specialcheckboxSwitch1' value=''>Text<i for="specialcheckboxSwitch1"></i>
</label>

Styleguide Form.Checkboxes Switch
*/

.specialradiobox,
.specialcheckbox,
.specialcheckboxSwitch {
  position: relative;
  line-height: $inputLineHeight;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 34px;
  color: $inputColor;

  i {
    font-style: normal;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    border: $inputBorderWidth solid $inputBorderColor;
    border-radius: 50%;
    top: -2px;
    display: block;
    pointer-events: none;
    margin-bottom: 0;
    font-family: $font-family;

    &:after {
      content: " ";
      pointer-events: none;
      position: absolute;
      width: 14px;
      height: 14px;
      left: 4px;
      top: 4px;
      opacity: 0;
      transition: opacity 0.1s ease-in;
      background-color: $inputBorderColorFocus;
      border-radius: 50%;
      display: block;
    }
  }

  input {
    position: absolute;
    width: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0;

    &:checked {
      & + i {
        &:after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      & + i {
        background: $light-gray;
        border-color: $light-gray;

        &:after {
          color: $gray-base;
        }
      }
    }

    &:focus {
      & + i {
        border-color: $inputBorderColorFocus;
      }
    }
  }

  &:active {
    &:not(.disabled) {

      i {
        &:after {
          opacity: 0.7;
        }
      }

      input {
        &:disabled {
          &:not(:checked) {
            & + i {
              &:after {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &.disabled {
    cursor: default;
    color: $inputColor;
  }
}

.specialcheckbox {
  i {
    border-radius: $inputBorderRadius;

    &:after {
      left: -1px;
      content: $i-check;
      font-family: $icon-family;
      color: $inputBorderColorFocus;
      top: -1px;
      opacity: 0;
      line-height: 24px;
      transition: opacity 0.1s ease-in;
      font-size: 24px;
      background-color: transparent;
    }
  }

  input {
    width: 24px;
    height: 24px;
    left: 0;
    top: 0;
  }
}

.specialcheckboxSwitch {
  padding-left: 45px;
  margin-bottom: 0;

  i {
    background: $inputBorderColor;
    border-radius: 16px;
    width: 36px;
    height: 20px;
    border: 0;
    position: absolute;
    margin-top: 3px;
    transition: 0.2s ease-in-out;

    &:after {
      content: "";
      display: block;
      background: $light-gray;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: 2px;
      opacity: 1;
      transition: 0.2s ease-in-out;
    }
  }

  input {
    width: 36px;
    height: 20px;
    left: 0;
    top: 0;

    &:checked {
      & + i {
        background: $inputBorderColorFocus;

        &:after {
          left: 17px;
          background: $white;
        }
      }
    }
  }
}