
.icon-favorites:before {
  content: $i-heart;
}

.icon-favorites-filled:before {
  content: $i-heart-filled;
}

.icon-spinner-up:before {
  content: $i-plus;
}

.icon-spinner-down:before {
  content: $i-minus;
}