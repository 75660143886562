/*
Nav

Markup:
<nav class="nav">
  <ul>
    <li>
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
    <li>
      <a href="#">Level 1</a>
    </li>
  </ul>
</nav>

Styleguide Navigations.Nav
*/

.nav {
  display: flex;
  flex-wrap: nowrap;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: $navColorLink;
    text-decoration: none;

    &:hover, &:focus, &:active, &.active, &.active_tree {
      color: $navColorLinkHover;
    }
  }

  li {
    &:hover, &:focus, &:active, &.active, &.active_tree {
      > a {
        color: $navColorLinkHover;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * $navSpaceBetweenXS / 2);
    margin-right: calc(-1 * $navSpaceBetweenXS / 2);
    @media (min-width: $screen-sm) {
      margin-left: calc(-1 * $navSpaceBetween / 2);
      margin-right: calc(-1 * $navSpaceBetween / 2);
    }

    > li {
      padding-left: calc($navSpaceBetweenXS / 2);
      padding-right: calc($navSpaceBetweenXS / 2);
      @media (min-width: $screen-sm) {
        padding-left: calc($navSpaceBetween / 2);
        padding-right: calc($navSpaceBetween / 2);
      }
    }
  }

  > a {
    margin-right: calc($navSpaceBetweenXS);
    @media (min-width: $screen-sm) {
      margin-right: calc($navSpaceBetween);
    }
  }
}