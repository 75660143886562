
.footer {
  padding: 10px calc($grid-gap / 2);

  @media (min-width: $screen-sm) {
    padding: 30px $grid-gap;
  }

  .container {
    max-width: 400px;
    padding: 0;
  }

  .nav {
    display: flex;
    width: 100%;

    > ul {
      width: 100%;
      justify-content: center;
      margin: 0;
      gap: 30px;

      > li {
        padding: 0;
      }
    }
  }
}