/*
Tables

Markup:
<table>
<tr>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
<th>Header</th>
</tr>
<tr>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
<td>Cell</td>
</tr>
</table>


Styleguide Base.Tables
*/

table {
  font-size: $font-size-base;
}

td,
th {
  padding: 10px 5px;
  border-bottom: 0;
  border-bottom: 1px solid $tableBorderColor;
}

tr:first-child {
  td,th {
    border-top: 1px solid $tableBorderColor;
  }
}
.table_wrapper {
  max-width: 100%;
  overflow-x: scroll;

  @media (max-width: $screen-xs-max){
    table{
      font-size: $font-size-small;
    }
  }
}