/*
Button Link

Markup:
<a href="#" class="buttonLink">Button Link</a> <button class="buttonLink">Button Link</button>

Styleguide Base.Button Link
*/

%buttonLinkHover {
  background: transparent;
  text-decoration: none;
  &:after {
    width: 100%;
  }
}

.buttonLink {
  display: inline-block;
  color: $text-base;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  padding: 5px 0 3px 0;
  border: 0;
  text-decoration: none;

  &:after {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $text-base;
    height: 2px;
    transition: width 0.2s ease-in;
  }

  &:hover, &:focus, &:active {
    @extend %buttonLinkHover;
  }
}