
.headerLogo {
  max-width: 140px;

  @media (min-width: $screen-sm) {
    max-width: 200px;
  }

  a, img {
    display: block;
  }
}

.headerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px calc($grid-gap / 2);

  @media (min-width: $screen-sm) {
    padding: 30px $grid-gap;
  }
}

.headerLinks {
  display: flex;
  gap: 20px;
  font-size: $font-size-small;

  @media (min-width: $screen-sm){
    font-size: $font-size-base;
    gap: 30px;
  }
}