/*
Button

Markup:
<a href="#" class="button {{modifier_class}}">Button</a> <button class="button {{modifier_class}}">Button</button>

.button--primary - Primary
.button--action - Action
.button--danger - Danger
:disabled - Disabled

Styleguide Base.Buttons
*/

%buttonHover {
  background: $buttonBackgroundHover;
  border-color: $buttonBorderColorHover;
  color: $white;
  text-decoration: none;
}

%button,
button,
.button,
.button_reset,
.button_save{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $buttonBorderColor;
  color: $buttonColor;
  border-radius: 0;
  cursor: pointer;
  background: $buttonBackground;
  text-align: center;
  text-decoration: none;
  line-height: $inputLineHeightXS;
  font-size: $inputFontSizeXS;
  padding: $inputPaddingTopXS $inputPaddingRightXS $inputPaddingBottomXS $inputPaddingLeftXS;
  transition: ease-in-out 0.1s;
  position: relative;

  @media (min-width: $screen-sm) {
    line-height: $inputLineHeight;
    font-size: $inputFontSize;
    padding: $inputPaddingTop $inputPaddingRight $inputPaddingBottom $inputPaddingLeft;
  }

  &:hover, &:focus {
    @extend %buttonHover;
  }

  &:active {
    background: $buttonBackgroundActive;
    border-color: $buttonBorderColorActive;
    color: $white;
    text-decoration: none;
  }

  &--primary, &_save{
    background: $white;
    color: $buttonPrimaryBackground;
    border-color: $buttonPrimaryBorderColor;

    &:hover, &:focus {
      background: $buttonPrimaryBackground;
      border-color: $buttonPrimaryBackground;
      color: $white;
    }

    &:active {
      background: $buttonPrimaryBackgroundActive;
      border-color: $buttonPrimaryBorderColorActive;
      color: $buttonPrimaryColorActive;
    }
  }

  &--action {
    background: $buttonActionBackground;
    color: $buttonActionColor;
    border-color: $buttonActionBorderColor;

    &:hover, &:focus {
      background: $buttonActionBackgroundHover;
      border-color: $buttonActionBorderColorHover;
      color: $buttonActionColorHover;
    }

    &:active {
      background: $buttonActionBackgroundActive;
      border-color: $buttonActionBorderColorActive;
      color: $buttonActionColorActive;
    }
  }

  &--danger {
    background: $danger;
    color: $white;
    border-color: $danger;

    &:hover, &:focus {
      background: $buttonActionBackgroundHover;
      border-color: $buttonActionBorderColorHover;
      color: $buttonActionColorHover;
    }

    &:active {
      background: $buttonActionBackgroundActive;
      border-color: $buttonActionBorderColorActive;
      color: $buttonActionColorActive;
    }
  }

  &--sm {
    @if ($inputPaddingTopXS != $inputPaddingTop) {
      padding-top: $inputPaddingTopXS;
    }
    @if ($inputPaddingRightXS != $inputPaddingRight) {
      padding-right: $inputPaddingRightXS;
    }
    @if ($inputPaddingBottomXS != $inputPaddingBottom) {
      padding-bottom: $inputPaddingBottomXS;
    }
    @if ($inputPaddingLeftXS != $inputPaddingLeft) {
      padding-left: $inputPaddingLeftXS;
    }
    @if ($inputLineHeightXS != $inputLineHeight) {
      line-height: $inputLineHeightXS;
    }
    @if ($inputFontSizeXS != $inputFontSize) {
      font-size: $inputFontSizeXS;
    }
  }

  @media (min-width: $screen-sm) {
    &--xl {
      @if ($inputPaddingTopXL != $inputPaddingTop) {
        padding-top: $inputPaddingTopXL;
      }
      @if ($inputPaddingRightXL != $inputPaddingRight) {
        padding-right: $inputPaddingRightXL;
      }
      @if ($inputPaddingBottomXL != $inputPaddingBottom) {
        padding-bottom: $inputPaddingBottomXL;
      }
      @if ($inputPaddingLeftXL != $inputPaddingLeft) {
        padding-left: $inputPaddingLeftXL;
      }
      @if ($inputLineHeightXL != $inputLineHeight) {
        line-height: $inputLineHeightXL;
      }
      @if ($inputFontSizeXL != $inputFontSize) {
        font-size: $inputFontSizeXL;
      }
    }
  }

  &.is-loading,
  &.is-success,
  &.is-error {
    background: $middle-gray !important;
    border-color: $middle-gray !important;
    cursor: not-allowed;
    pointer-events: none;
    padding-right: 40px;
    padding-left: 10px;

    .icon {
      opacity: 0;
    }

    &:after {
      position: absolute;
      right: 15px;
      top: 50%;
      font-family: $icon-family;
      translate: 0 -50%;
      font-size: 24px;
    }
  }

  &.is-loading {
    cursor: wait;
    color: $black !important;

    &:after {
      content: $i-loading;
      opacity: 1;
      animation: rotation 1s infinite ease-in-out;
    }
  }

  &.is-success {
    background: $success !important;
    border-color: $success !important;
    color: $black !important;

    &:after {
      content: $i-check;
      animation: scaleRequestIconBtn 1s forwards ease-in-out;
    }
  }

  &.is-error {
    background: $danger !important;
    border-color: $danger !important;
    color: $white !important;

    &:after {
      content: $i-close;
      animation: scaleRequestIconBtn 1s forwards ease-in-out;
    }
  }
}

button.disabled,
.button_disabled,
.btn-disabled,
.button[disabled] {
  @extend %button;
  background: $light-gray;
  color: $text-base;
  border-color: $light-gray;
  cursor: not-allowed;

  &:hover, &:focus, &:active {
    background: $light-gray;
    border-color: $light-gray;
    color: $text-base;
  }
}

@keyframes scaleRequestIconBtn {
  0% {
    opacity: 0;
    scale: 0
  }
  50% {
    opacity: 1;
    scale: 1.2
  }
  100% {
    opacity: 1;
    scale: 1
  }
}