/*
Modals

Markup:
<a href="javascript:void(0)" class="button" data-modal='id-{{modifier_class}}'>Open Modal</a>
<div class="modal {{modifier_class}}" id="id-{{modifier_class}}">
    <div class="modal__dialog" role="document">
        <div class="modal__content">
            <div class="modal__header">
                <div class="modal__title">Title<br/><small>Subtitle</small></div>
                <div class="modal__close" data-close="modal">
                    <i class="icon icon-close"></i>
                </div>
            </div>
            <div class="modal__body">
              Lorem Ipsum
            </div>
            <div class="modal__footer">
                <button class="button button--action">Save</button>
                <span class="button" data-close="modal">Abort</span>
            </div>
        </div>
    </div>
</div>

.modal--info - Info
.modal--error - Error
.modal--success - Success

Styleguide Components.Modals
*/

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $zindexModal;
  opacity: 0;
  transition: 0.4s ease;
  pointer-events: none;
  align-items: flex-end;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: $screen-sm) {
    padding: 30px;
    align-items: flex-start;
  }

  @media (min-width: $screen-lg) {
    padding: 60px;
  }

  &__dialog {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    transition: 0.4s ease;
    background: $white;
    box-shadow: 0 5px 30px fade_out(#000000, 0.8);
    transform: translateY(20%);

    @media (max-width: $screen-xs-max){
      max-height: 100%;
      max-height: 100dvh;
      overflow-x: hidden;
      overflow-y: auto;
    }

    @media (min-width: $screen-sm) {
      border-radius: $modalBorderRadius;
      overflow: hidden;
      transform: translateY(-20%);
    }

    @media (min-width: $screen-lg) {
      box-shadow: 0 10px 60px fade_out(#000000, 0.8);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease-in;
    opacity: 1;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    opacity: 0.6;

    &:hover, &:focus {
      color: $primary;
    }
  }

  &__header {
    padding: 20px 40px 0 20px;

    @media (min-width: $screen-sm) {
      padding: 30px 50px 0 30px;
    }
  }

  &__body {
    padding: 20px;

    @media (min-width: $screen-sm) {
      padding: 30px;
      padding-top: 0;
    }
  }

  &__footer {
    padding: 20px;

    @media (min-width: $screen-sm) {
      padding: 30px;
    }

    &:not(.modal__footer--bg) {
      padding-top: 0;
    }
  }

  &__title {
    font-weight: $bold;
    font-size: $font-size-big;
    margin-bottom: 1em;

    small {
      font-size: $font-size-base;
      font-weight: $normal;
    }
  }

  &--info,
  &--error,
  &--success {
    .modal__header {
      padding: 0;
    }

    .modal__dialog {
      text-align: center;
      max-width: 420px;
      padding-top: 50px;

      @media (min-width: $screen-sm) {
        padding-top: 130px;
      }

      &:before {
        content: $icon-modal-warning;
        font-family: $icon-family;
        border: 2px solid $warning;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        border-radius: 50%;
        color: $warning;
        transform: translateX(-50%);
        line-height: 1;
        top: 20px;
        font-size: 24px;
        width: 40px;
        height: 40px;

        @media (min-width: $screen-sm) {
          top: 40px;
          font-size: 40px;
          width: 60px;
          height: 60px;
          border-width: 3px;
        }
      }
    }
  }

  &--error {
    .modal__dialog:before {
      content: $icon-modal-danger;
      border-color: $danger;
      color: $danger;
    }
  }

  &--success {
    .modal__dialog:before {
      content: $icon-modal-success;
      border-color: $success;
      color: $success;
    }
  }

  &--loading {
    .modal__title {
      opacity: 0;
    }

    .modal__body {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;

      .icon {
        font-size: 60px;
        animation: rotation 1s infinite linear;
      }
    }
  }

  &--lg {
    .modal__dialog {
      max-width: 1200px;
    }
  }

  &--md {
    .modal__dialog {
      max-width: 900px;
    }
  }

  &--sm {
    .modal__dialog {
      max-width: 600px;
    }
  }

  &--xs {
    .modal__dialog {
      max-width: 420px;
    }
  }

  &--white {
    .modal__dialog {
      background: $white;
      color: $black;
    }
  }

  &.open {
    opacity: 1;
    pointer-events: auto;

    .modal__dialog {
      transform: none;
    }
  }

  &--disabled {
    .modal__dialog {
      pointer-events: none;
      margin-top: -10px;
    }
    .modal__content {
      opacity: 0.2;
    }
  }
}

.modalBg {
  background: $modalBackground;
  z-index: ($zindexModal - 1);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}

body,
html {
  &.modal-open {
    overflow: hidden;

    @media (min-width: $screen-md){
      padding-right: 6px;
    }
  }
}