@use 'intl-tel-input/build/css/intlTelInput.min.css';

.iti {
  display: flex;
  width: 100%;
  &__flag {
    background-image: url("/plugins/intTelInput/img/flags.png");

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("/plugins/intTelInput/img/flags@2x.png");
    }
  }
  &--separate-dial-code {
    .iti__selected-flag {
      background: transparent;
    }
  }
}

.inputGroup {
  .iti__flag-container {
    & + input {
      border-top-left-radius: $inputBorderRadius !important;
      border-bottom-left-radius: $inputBorderRadius !important;
    }
  }
  .iti__country-list {
    margin-top:10px;
    padding-top: 10px;
    border-radius: $inputBorderRadius;
    border-color: $inputBorderColor;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  }
}