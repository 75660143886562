/*
Infield Input Groups

Markup:
<div class="infieldInputGroup">
  <label for="id">Label</label>
  <div class="inputGroup {{modifier_class}}">
    <input type="text" id="id" />
  </div>
</div><br/>
<strong>Filled</strong><br/><br/>
<div class="infieldInputGroup is-filled">
  <label for="id">Label</label>
  <div class="inputGroup {{modifier_class}}">
    <input type="text" id="id" value="Text" />
  </div>
</div>

Styleguide Form.Infield Input Groups
*/

.infieldInputGroup {
  position: relative;

  > label {
    position: absolute;
    top: $inputPaddingTopXS;
    left: $inputPaddingLeftXS;
    margin-top: 1px;
    line-height: $inputLineHeightXS;
    transition: 0.2s ease-in-out;
    z-index: 1;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - #{$inputPaddingLeftXS*2});

    @media (min-width: $screen-sm) {
      top: $inputPaddingTop;
      left: $inputPaddingLeft;
      line-height: $inputLineHeight;
      max-width: calc(100% - #{$inputPaddingLeft*2});
    }
  }

  input,
  select,
  textarea {
    transition: padding 0.2s ease-in-out;
  }

  &--select,
  &.is-focus,
  &.is-filled {
    > label {
      top: 0;
      transform: translate(-5px, -50%);
      color: $dark-gray;
      font-size: 0.7em;
      background: $white;
      line-height: 1;
      padding-left: 5px;
      padding-right: 5px;
      max-width: 100%;
    }
  }
}