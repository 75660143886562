$main-color-white: #ffffff;
$main-color-black: #2B2B2C;
$main-color-gray-base: #333333;
$main-color-middle-gray: #d9dddd;
$main-color-dark-gray: $main-color-gray-base;
$main-color-light-gray: #e6e6e6;
$main-color-primary: #e30018;
$main-color-primary-dark: darken($main-color-primary,5);
$main-color-primary-darken: darken($main-color-primary,10);
$main-color-secondary: lighten($main-color-primary,10);
$main-color-danger: #D32D2D;
$main-color-warning: #FF9903;
$main-color-success: #FF9903;
$main-color-action: #009ee3;
$main-color-info: $main-color-light-gray;

$cssColorVariables: (
        "main-color-white": $main-color-white,
        "main-color-black": $main-color-black,
        "main-color-gray-base": $main-color-gray-base,
        "main-color-middle-gray": $main-color-middle-gray,
        "main-color-dark-gray": $main-color-dark-gray,
        "main-color-light-gray": $main-color-light-gray,
        "main-color-primary": $main-color-primary,
        "main-color-primary-dark": $main-color-primary-dark,
        "main-color-primary-darken": $main-color-primary-darken,
        "main-color-secondary": $main-color-secondary,
        "main-color-action": $main-color-action,
        "main-color-danger": $main-color-danger,
        "main-color-warning": $main-color-warning,
        "main-color-success": $main-color-success,
        "main-color-info": $main-color-info
);

$favoritesIconInactive: $i-star;
$favoritesIconActive: $i-star-filled;

@use "sass:math";
@use "sass:map";
@import "../../../../node_modules/bootstrap/scss/functions";

$theme-colors-rgb: map-loop($cssColorVariables, to-rgb, "$value")  !default;

$cssColorContrastVariables: (
        "main-color-primary-contrast": colorTextContrast($main-color-primary),
        "main-color-primary-dark-contrast": colorTextContrast($main-color-primary-dark),
        "main-color-primary-darken-contrast": colorTextContrast($main-color-primary-darken),
        "main-color-secondary-contrast": colorTextContrast($main-color-secondary),
        "main-color-action-contrast": colorTextContrast($main-color-action),
        "main-color-danger-contrast": colorTextContrast($main-color-danger),
        "main-color-warning-contrast": colorTextContrast($main-color-warning),
        "main-color-success-contrast": colorTextContrast($main-color-success),
        "main-color-info-contrast": colorTextContrast($main-color-info)
);

$cssStyleVariables: (
        "main-font-family": "Public Sans,Helvetica, Arial, sans-serif",
        "main-font-weight": 400,
        "main-font-weight-bold": 600,
        "main-font-size-base": 1.6rem,
        "main-font-size-small": 1.3rem,
        "main-font-size-big": 1.8rem,
        "main-line-height": 1.4,
        "main-link-color": $main-color-black,
        "main-link-color-hover": $main-color-primary
);

:root {
  @each $key, $value in $cssColorVariables {
    --#{$key}: #{$value};
  }
  @each $key, $value in $cssColorContrastVariables {
    --#{$key}: #{$value};
  }
  @each $key, $value in $cssStyleVariables {
    --#{$key}: #{$value};
  }
}

$white: var(--main-color-white);
$black: var(--main-color-black);
$gray-base: var(--main-color-gray-base);
$middle-gray: var(--main-color-middle-gray);
$dark-gray: var(--main-color-dark-gray);
$light-gray: var(--main-color-light-gray);
$primary: var(--main-color-primary);
$primary-dark: var(--main-color-primary-dark);
$primary-darken: var(--main-color-primary-darken);
$secondary: var(--main-color-secondary);
$danger: var(--main-color-danger);
$warning: var(--main-color-warning);
$success: var(--main-color-success);
$action: var(--main-color-action);
$info: var(--main-color-info);

$primary-contrast: var(--main-color-primary-contrast);
$primary-dark-contrast: var(--main-color-primary-dark-contrast);
$primary-darken-contrast: var(--main-color-primary-darken-contrast);
$secondary-contrast: var(--main-color-secondary-contrast);
$action-contrast: var(--main-color-action-contrast);
$danger-contrast: var(--main-color-danger-contrast);
$warning-contrast: var(--main-color-warning-contrast);
$success-contrast: var(--main-color-success-contrast);
$info-contrast: var(--main-color-info-contrast);

$body-background: var(--main-color-white);
$text-base: var(--main-color-dark-gray);

$backgroundColorScrollbar: $gray-base;
$backgroundColorScrollbarActive: $black;

//fonts

$font-family: var(--main-font-family);
$icon-family: "icons", Helvetica, Arial, sans-serif;

$font-size-base: var(--main-font-size-base);
$font-size-small: var(--main-font-size-small);
$font-size-big: var(--main-font-size-big);

$normal: var(--main-font-weight);
$bold: var(--main-font-weight-bold);

$line-height-base: var(--main-line-height);

$link: var(--main-link-color);
$link-hover: var(--main-link-color-hover);
$link-decoration: underline;
$link-decoration-hover: underline;

$headings-color: $text-base;
$headings-font-family: $font-family;
$headings-font-weight: $bold;
$headings-line-height: $line-height-base;
$headings-highlighted-font-weight: $normal;
$headings-highlighted-line-height: 1;

$h1-font-size: 2.7rem;
$h2-font-size: 2.2rem;
$h3-font-size: 1.9rem;
$h4-font-size: 1.7rem;
$h5-font-size: 1.7rem;
$h6-font-size: 1.7rem;

$h1-font-size-breakpoints: () !default;
$h2-font-size-breakpoints: () !default;
$h3-font-size-breakpoints: () !default;
$h4-font-size-breakpoints: () !default;
$h5-font-size-breakpoints: () !default;
$h6-font-size-breakpoints: () !default;
$highlighted-h1-breakpoints: () !default;
$highlighted-h2-breakpoints: () !default;
$highlighted-h3-breakpoints: () !default;

$h1-font-size-breakpoints: (
        xs: 2.4rem,
        md: $h1-font-size
);

$h2-font-size-breakpoints: (
        xs: 2rem,
        md: $h2-font-size
);

$highlighted-h1-breakpoints: (
        xs: 3.4rem,
        md: 4rem,
        lg: 6.7rem
);

$highlighted-h2-breakpoints: (
        xs: 2.8rem,
        md: 3.4rem,
        lg: 4.7rem
);

$h1-font-weight: $headings-font-weight;
$h2-font-weight: $headings-font-weight;
$h3-font-weight: $headings-font-weight;
$h4-font-weight: $headings-font-weight;
$h5-font-weight: $headings-font-weight;
$h6-font-weight: $headings-font-weight;

//spacers

$spacer-xsmall: 5px;
$spacer-base: 10px;
$spacer-small: 20px;
$spacer-large: 40px;
$spacer-xlarge: 80px;

$spacers: (
        0: 0,
        spacer-xsmall: $spacer-xsmall,
        spacer-base: $spacer-base,
        spacer-small: $spacer-small,
        spacer-large: $spacer-large,
        spacer-xlarge: $spacer-xlarge,
) !default;

$spacer-large-breakpoints: (
        xs: 20px,
        sm: 40px,
) !default;

$spacer-xlarge-breakpoints: (
        xs: 30px,
        sm: 80px,
) !default;

//grid

$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1250px,
        xlg: 1540px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$screen-xs: 481px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

$screen-md: 1024px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

$screen-lg: 1250px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

$screen-xlg: 1540px;
$screen-xlg-min: $screen-xlg;
$screen-xlg-desktop: $screen-lg-min;

$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xlg-min - 1);

$screen-s9-max: 369px;
$screen-iphone-min: 374px;

$grid-columns: 12;
$grid-gap: 40px;
$grid-gap-small: 20px;
$grid-gap-large: 80px;

//containers

$container: 1560px;
$container-xsmall: 1020px;
$container-small: 1200px;
$container-large: 1720px;

$zindexHeader: 100;
$zindexHeaderHovermenu: 101;
$zindexOverlay: 103;
$zindexMobileFilter: 104;
$zindexMobileCustomSelect: 104;
$zindexIconbarHover: 104;
$zindexTooltip: 104;
$zindexMobileMenu: 105;
$zindexFullscreenGallery: 106;
$zindexModal: 107;
$zindexLoadingScreen: 108;
$zindexFlashmessages: 109;

$switchMobileMenu: $screen-sm-max;

$border-radius: 5px;

//inputs

$inputPaddingTop: 14px;
$inputPaddingRight: 20px;
$inputPaddingBottom: $inputPaddingTop;
$inputPaddingLeft: $inputPaddingRight;
$inputLineHeight: 20px;
$inputFontSize: $font-size-base;

$inputPaddingTopXS: 10px;
$inputPaddingRightXS: 15px;
$inputPaddingBottomXS: $inputPaddingTopXS;
$inputPaddingLeftXS: $inputPaddingRightXS;
$inputLineHeightXS: 18px;
$inputFontSizeXS: $font-size-small;

$inputPaddingTopXL: 18px;
$inputPaddingRightXL: 30px;
$inputPaddingBottomXL: $inputPaddingTopXL;
$inputPaddingLeftXL: $inputPaddingRightXL;
$inputLineHeightXL: 22px;
$inputFontSizeXL: $font-size-big;

$inputColor: $black;
$inputBackground: $white;

$inputBorderWidth: 1px;
$inputBorderColor: $middle-gray;
$inputBorderColorFocus: $primary;
$inputBorderRadius: 2px;

$inputMinHeight: $inputPaddingTop + $inputPaddingBottom + $inputLineHeight + $inputBorderWidth*2;
$inputMinHeightXS: $inputPaddingTopXS + $inputPaddingBottomXS + $inputLineHeightXS + $inputBorderWidth*2;
$inputMinHeightXL: $inputPaddingTopXL + $inputPaddingBottomXL + $inputLineHeightXL + $inputBorderWidth*2;

$inputGroupAddonWidth: $inputPaddingTop + $inputLineHeight + $inputPaddingBottom + $inputBorderWidth * 2;
$inputGroupAddonWidthXS: $inputPaddingTopXS + $inputLineHeightXS + $inputPaddingBottomXS + $inputBorderWidth * 2;
$inputGroupAddonIconFontSize: 24px;
$inputGroupAddonIconFontSizeXS: 20px;

$labelLeftWidth: 140px;

//buttons

$buttonBackground: $light-gray;
$buttonBorderColor: $light-gray;
$buttonColor: $black;
$buttonBorderWidth: 1px;
$buttonBorderRadius: 6px;

$buttonBackgroundHover: $primary;
$buttonBorderColorHover: $primary;
$buttonColorHover: $black;

$buttonBackgroundActive: $primary;
$buttonBorderColorActive: $primary;
$buttonColorActive: $primary-contrast;

$buttonPrimaryBackground: $primary;
$buttonPrimaryBorderColor: $primary;
$buttonPrimaryColor: $primary-contrast;

$buttonPrimaryBackgroundHover: $primary-dark;
$buttonPrimaryBorderColorHover: $primary-dark;
$buttonPrimaryColorHover: $primary-dark-contrast;

$buttonPrimaryBackgroundActive: $primary-darken;
$buttonPrimaryBorderColorActive: $primary-darken;
$buttonPrimaryColorActive: $primary-darken-contrast;

$buttonActionBackground: $black;
$buttonActionBorderColor: $black;
$buttonActionColor: $white;

$buttonActionBackgroundHover: $primary;
$buttonActionBorderColorHover: $primary;
$buttonActionColorHover: $primary-contrast;

$buttonActionBackgroundActive: $primary-dark;
$buttonActionBorderColorActive: $primary-dark;
$buttonActionColorActive: $primary-contrast;

//navigation
$mainnavColorLink: var(--main-color-text-base);
$mainnavColorLinkHover: var(--main-color-primary);
$mainnavLinkBorderColor: $black;
$mainnavLinkHeightActive: 2px;
$mainnavLinkActiveColor: $black;
$mainnavFontSize: $font-size-base;
$mainnavFontSizeXLG: 1.7rem;
$mainnavSpaceBetweenLinks: 20px;
$mainnavSpaceBetweenLinksLG: 30px;
$mainnavFontWeightLevel1: $bold;

$navGroupColorLink: $text-base;
$navGroupColorLinkHover: $primary;
$navGroupSpaceBetween: 20px;
$navGroupSpaceLinksBetween: 15px;
$navGroupBorderColorMobile: $middle-gray;
$navGroupFontWeightLevel1: $bold;

$navColorLink: $text-base;
$navColorLinkHover: $primary;
$navSpaceBetweenXS: 20px;
$navSpaceBetween: 30px;

$megaMenuFontSizeBase: $font-size-base;
$megaMenuFontWeightBase: $normal;
$megaMenuFontSizeLevel1: $font-size-base;
$megaMenuFontWeightLevel1: $bold;

$subnavFontSizeBase: $font-size-base;
$subnavFontWeightBase: $normal;
$subnavFontSizeLevel1: $font-size-big;
$subnavFontWeightLevel1: $bold;
$subnavigatonLinkColor: $text-base;
$subnavigatonLinkColorHover: $primary;
$subnavigatonLinkColorActive: $primary;

$toggleNavigationBackgroundBar: $black;

// scss-docs-start position-map
$position-values: (
        0: 0,
        50: 50%,
        100: 100%
) !default;
// scss-docs-end position-map

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
) !default;
$border-style:                solid !default;
$border-color:                $gray-base !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

//banner
$bannerHeadlineFontSizeBreakpoints: (
        xs: 3rem,
        md: 3.4rem,
        lg: 3.7rem
);
$bannerSublineFontSizeBreakpoints: (
        xs: 1.8rem,
        md: $h1-font-size
);

//table
$tableBorderColor: $inputBorderColor;

//collections
$mediaBoxBorderRadius: $inputBorderRadius;
$newsBoxBorderRadius: $inputBorderRadius;

//alerts
$alert-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "action": $action,
        "danger": $danger,
        "warning": $warning,
        "success": $success
);

//breadcrumb
$breadcrumbFontSize: $font-size-small;
$breadcrumbLinkColor: $dark-gray;
$breadcrumbLinkColorHover: $primary;
$breadcrumbLinkColorCurrent: $black;
$breadcrumbLinkFontWeightCurrent: $normal;
$breadcrumbBreakScrollable: $screen-sm-max;

//contentbox
$contentBoxBorderRadius: $inputBorderRadius;
$contentBoxInBoxHeadlineFontSizeBreakpoints: (
        xs: 1.6rem,
        sm: 2rem,
        md: 2.6rem,
        lg: 3rem
);

//fullscreenGallery
$fullscreenGalleryThumbs: 80px;
$fullscreenGalleryThumbsMobil: 60px;

//newsletter
$nlFooterFormButtonColor: $white;
$nlFooterFormButtonBackground: $black;
$nlFooterFormButtonBorderColor: $black;

//scrollbar
$scrollbarNavButtonWidthHeight: 40px;
$scrollbarBreakpointArrowsOutside: $container;

//slidecontent
$slidecontentBorderColor: $inputBorderColor;

//slideshow
$slideshowNavButtonWidthHeight: 40px;
$slideshowBreakpointArrowsOutside: $container;

//flashmessages
$fontSizeFlashMessage: $font-size-small;
$widthFlashMessage: 400px;

//modals
$icon-modal-success: $i-check;
$icon-modal-danger: $i-exclamation;
$icon-modal-warning: $i-exclamation;
$modalBorderRadius: 10px;
$modalBackground: fade_out(#cccccc, 0.3);

//overlay
$overlayBackground: rgba(255, 255, 255, .7);

//pagination
$paginationColor: $middle-gray;
$paginationColorActive: $white;
$paginationBackground: transparent;
$paginationBackgroundActive: $black;
$paginationLinkColor: $black;
$paginationLinkColorHover: $black;
$paginationLinkBackgroundHover: $middle-gray;

//processbar
$processbarColor: $middle-gray;
$processbarColorActive: $text-base;
$processbarItemWidth: 200px;

//searchbar
$searchBarBackground: $light-gray;
$searchBarBorderColor: $light-gray;

//tabs
$tabsBorderColor: $inputBorderColor;

//tags
$tagsBackground: $light-gray;
$tagsColor: $text-base;
$tagsBackgroundHover: $primary;
$tagsColorHover: $primary-contrast;
$tagsBackgroundActive: $primary-dark;
$tagsColorActive: $primary-dark-contrast;

//accountBox
$accountBoxBorderRadius: $inputBorderRadius;
$accountBoxBorderColor: $inputBorderColor;
$accountBoxActionColor: $dark-gray;
$accountBoxActionHoverColor: $black;

//categoryBox
$categoryBoxHeadlineFontSizeBreakpoints: (
        xs: 1.6rem,
        sm: 2rem,
        md: 2.6rem,
        lg: 3rem
);
$categoryBoxBackground: $black;
$categoryBoxColor: $white;
$categoryBoxBackgroundHover: $primary;
$categoryBoxColorHover: $black;

//categoryList
$categoryHeadlineFontSizeBreakpoints: (
        xs: 1.6rem,
        sm: 2rem,
        md: 2.6rem,
        lg: 3rem
);

//categoryItempreview
$categoryItempreviewColor: $text-base;
$categoryItempreviewColorHover: $text-base;
$categoryItempreviewHeadlineColorHover: $primary;
$categoryItempreviewBorderColor: $inputBorderColor;
$categoryItempreviewBorderColorActive: $inputBorderColorFocus;

//documentarchive
$documentArchiveBorderColor: $inputBorderColor;
$documentArchiveWidthCell: 120px;
$documentArchiveWidthCellLG: 180px;

//filter
$filterSwitchMobile: $screen-xs-max;

//itemcardImages
$itemcardImagesMaxWidth: 800px;
$itemcardImagesThumbsBorderColor: $middle-gray;
$itemcardImagesThumbsBorderColorActive: $black;
$itemcardImagesThumbsWidth: 104px;
$itemcardImagesThumbsWidthBreakpoint: 70px;
$itemcardImagesDirectionBreakpoint: $screen-xs-max;
$itemcardImagesThumbsMobileHide: true;

//itemcard VariantSelection
$variantSelectionBorderColor: $inputBorderColor;
$variantSelectionBorderColorHover: $inputBorderColorFocus;
$variantSelectionBorderColorActive: $black;
$variantSelectionBorderRadius: $inputBorderRadius;
$variantSelectionWidth: 70px;

//itemlist
$itemlistMediaWidthXS: 60px;
$itemtableListBorderColor: $inputBorderColor;

$itemBoxListGap: $grid-gap-small;
$itemBoxListGapBetweenRows: 40px;
$itemBoxFontSize: $font-size-small;
$itemBoxFontSizeXS: calc($font-size-small * 0.9);

$itemBoxBreakpointsWidth: (
        xs: 130px,
        sm: 200px,
        md: 200px,
        lg: 250px,
        xlg: 250px,
);

$itemBoxSliderContainer: $container;
$itemBoxSliderGap: $grid-gap-small;
$itemBoxSliderCalcPadding: calc($grid-gap / 2 - $itemBoxSliderGap / 2);

$itemBasketBoxBorderColor: $middle-gray;

$itemcardColumnGap: $grid-gap;
$itemcardColumnGapLarge: $grid-gap-large;
$itemcardRowGap: $grid-gap;
$itemcardRowGapLarge: $grid-gap;

//item statistic
$statisticsItemBorderColor: $middle-gray;

//favorites
$favoritesIconInactive: $i-heart;
$favoritesIconActive: $i-heart-filled;

//quantity
$quantityBorderColor: $inputBorderColor;
$quantityWidth: 90px;
$quantityWidthSmall: 110px;

//order
$orderHeadlineFontSize: 1.6rem;
$orderHeadlineFontWeight: $bold;
$orderHeadlineFontColor: $text-base;

$orderQuantityButtonWidth: 90px;

$orderItemBorderColor: $middle-gray;

$orderItemBorderColor: $middle-gray;

//shipmentAdressList
$shipmentAddressListGap: $grid-gap-small;
$shipmentAddressListItemWidth: 270px;

//utilities
$utilities-colors: $theme-colors-rgb !default;
$enable-important-utilities: false;
$rfs-mq-value: 800px;
$rfs-breakpoint: 1200px;
$variable-prefix:             dc- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// scss-docs-start utilities-border-colors
$utilities-border: map-merge(
                $utilities-colors,
                (
                        "black": to-rgb(#000000),
                        "white": to-rgb(#FFFFFF)
                )
) !default;
$utilities-border-colors: map-loop($utilities-border, rgba-css-var, "$key", "border") !default;

$utilities-border-subtle: (
        "primary-subtle": var(--#{$prefix}primary-border-subtle),
        "secondary-subtle": var(--#{$prefix}secondary-border-subtle),
        "success-subtle": var(--#{$prefix}success-border-subtle),
        "info-subtle": var(--#{$prefix}info-border-subtle),
        "warning-subtle": var(--#{$prefix}warning-border-subtle),
        "danger-subtle": var(--#{$prefix}danger-border-subtle),
        "light-subtle": var(--#{$prefix}light-border-subtle),
        "dark-subtle": var(--#{$prefix}dark-border-subtle)
) !default;
// scss-docs-end utilities-border-colors

// scss-docs-start box-shadow-variables
$box-shadow:                  0 0 40px rgba(0, 0, 0, 0.08) !default;
$box-shadow-sm:               0 0 60px rgba(0, 0, 0, 0.08) !default;
$box-shadow-lg:               0 0 80px rgba(0, 0, 0, 0.08) !default;
$box-shadow-inset:            inset 0 0 40px rgba(0, 0, 0, 0.08) !default;

$iconbarIconWidth: 40px;
$iconbarIconHeight: 40px;
$iconbarIconFontSize: 24px;
$iconbarIconLabelFontSize: 1.3rem;
$iconbarIconColor: var(--main-color-text-base);
$iconbarIconColorHover: var(--main-color-primary);
$iconbarSpaceBetweenIcons: 20px;
$iconbarIconCountColor: var(--main-color-primary-contrast);
$iconbarIconCountBackground: var(--main-color-primary);
$iconbarBadgeWidth: 30px;
$iconbarBadgeHeight: 30px;
$iconbarBadgeBackgroundColor: $main-color-middle-gray;
$iconbarBadgeColor: colorTextContrast($main-color-middle-gray);

$showIconLabelBreakpoint: $screen-lg;

$tooltip-font-size:                 $font-size-small;
$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $black;
$tooltip-border-radius:             $border-radius;
$tooltip-opacity:                   .9;
$tooltip-padding-y:                 $spacer-base * .25;
$tooltip-padding-x:                 $spacer-base * .5;
$tooltip-margin:                    null; // TODO: remove this in v6

$tooltip-arrow-width:               .8rem;
$tooltip-arrow-height:              .4rem;
// fusv-disable
$tooltip-arrow-color:               null; //
// fusv-enable